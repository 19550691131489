<template>
    <BasicInformationCard title="Contact informatie" 
        :editRouterLink="{name: routeNames.USER_DETAIL_EDIT_CONTACT_INFORMATION, params: { userId: userId}}"
        :promises.sync="promises">
        <template slot="content">
            <v-container>
                <v-row >
                    <v-col cols="12" sm="6">
                        <BasicInformationItem description="Adres" :value="getUserContactInformation.address">
                            <template v-slot:value-display="slotProps">
                                <p v-if="slotProps.value" class="subtitle-1 font-weight-medium black--text">
                                    <span class="notranslate">
                                        {{ slotProps.value.street }}
                                        <br/>
                                        {{ slotProps.value.number }} {{ slotProps.value.bus }}
                                    </span>
                                    <br/>
                                    {{ slotProps.value.postalCode }} {{ slotProps.value.city }} {{ slotProps.value.region }}
                                    <br/>
                                    <country-flag v-if="slotProps.value.countryCode" :country="slotProps.value.countryCode" size="small" rounded/> {{ translateNationalitiy(slotProps.value.countryCode) }}
                                </p>
                            </template>
                        </BasicInformationItem>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-row>
                            <v-col>
                                <BasicInformationItem description="Werk email" :value="getUserContactInformation.email" noTranslate/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <BasicInformationItem description="Persoonlijke email" :value="getUserContactInformation.personalEmail" noTranslate/>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6">
                        <BasicInformationItem description="Telefoon" :value="getUserContactInformation.phoneNumber" noTranslate/>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <BasicInformationItem description="Contact voor noodgevallen" :value="getUserContactInformation.contacts" noTranslate>
                            <template v-slot:value-display="slotProps">
                                <p v-for="(contact, index) in slotProps.value" class="subtitle-1 font-weight-medium black--text" v-bind:key="index">
                                    {{ contact.firstName }} {{ contact.lastName }} <span v-if="contact.relation">({{ contact.relation }})</span>
                                    <br/>
                                    {{ contact.phoneNumber }} 
                                    <br/>
                                    {{ contact.email }}
                                </p>
                            </template>
                        </BasicInformationItem>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </BasicInformationCard>
</template>

<script>
import BasicInformationCard from '../shared/BasicInformationCard.vue';
import BasicInformationItem from '../shared/BasicInformationItem.vue';
import routeNames from "@/router/RouteNames";
import {translateNationalitiy, translateGender, translateCivilStatus, translateDependents} from "@/shared/utils/translateUtils";
import CountryFlag from 'vue-country-flag'

export default {
    name: "UserContactInformationComponent",
    props: {
        userId: {
            type: String,
            required: true
        }
    },
    components: {
        BasicInformationCard,
        BasicInformationItem,
        CountryFlag
    },
    methods: {
        translateNationalitiy,
        translateGender,
        translateCivilStatus,
        translateDependents,
    },
    data() {
        return {
            routeNames: routeNames,
            promises: [
                this.$store.dispatch('usersModule/fetchUserContactInformation', this.userId).then((result) => {
                    this.userContactInformation = result
                })
            ],
            userContactInformation: null
        }
    },
    computed: {
        getUserContactInformation() {
            return this.$store.state.usersModule.userContactInformation
        }
    }

}
</script>