<template>
  <div>
    <BasicDetailHeaderComponent :promises.sync="promises" :title="getBasicUser.name" :id="userId"
      retrieveAvatar="usersModule/getUserAvatar"
      :titleEditRoute="{ name: routeNames.USER_DETAIL_EDIT_NAME, params: { userId: userId } }"
      :return-link-route="{ name: routeNames.USER_OVERVIEW }" return-link-text="Terug naar werknemers"
      :active="getBasicUser.active" active-text="Actieve gebruiker" inactive-text="Inactieve gebruiker"
      menu-button-text="Acties"
      :avatarEditRoute="{ name: routeNames.USER_DETAIL_EDIT_AVATAR, params: { userId: userId } }"
      default-avatar="default-avatar.svg">
      <template v-slot:content>
        <v-col cols="12">
          <Editable :route="{ name: routeNames.USER_DETAIL_EDIT_JOB_TITLE, params: { userId: userId } }">Functie: {{
      getBasicUser.jobTitle ? getBasicUser.jobTitle : "Geen functie gedefinieerd"
    }}
          </Editable>
        </v-col>
        <v-col cols="12">
          <Editable :route="{ name: routeNames.USER_DETAIL_EDIT_STATUTE, params: { userId: userId } }">Statuut: {{
      getBasicUser.statute ? getBasicUser.statute : "Geen statuut gedefinieerd"
    }}
          </Editable>
        </v-col>
        <v-col v-if="getEnableTeamsOption" cols="12">
          Teams:
          <!-- TODO when using vue3, you can easily loop a map https://github.com/vuejs/vue/issues/6644#issuecomment-330519858 -->
          <template v-if="getBasicUser.teamNamesById && Object.keys(getBasicUser.teamNamesById).length > 0">
            <span v-for="(id, index) in Object.keys(getBasicUser.teamNamesById)" :key="id" class="notranslate">
              <router-link class="clickable" :to="{ name: routeNames.TEAM_DETAIL, params: { teamId: id } }">{{
      new Map(Object.entries(getBasicUser.teamNamesById)).get(id)
    }}</router-link>
              <span v-if="isLatestElementOfList(index, Object.keys(getBasicUser.teamNamesById))">, </span>
            </span>
          </template>
          <span v-else>Geen team</span>
        </v-col>
      </template>
      <template v-slot:actions>
        <v-list-item link v-if="showRegisterHourButton"
          :to="{ name: routeNames.MODIFY_USER_HOURS, params: { userId: userId, date: format(new Date(), 'yyyy-MM-dd') } }">
          <v-list-item-icon>
            <v-icon>mdi-alarm</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Registreer uren</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="onToggleActiveClick">
          <v-list-item-icon>
            <v-icon>{{ getBasicUser.active ? "mdi-account-cancel" : "mdi-account-check" }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ getBasicUser.active ? "Zet op inactief" : "Zet op actief" }}</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: routeNames.PASSWORD_CHANGE, params: { userId: userId } }">
          <v-list-item-icon>
            <v-icon>mdi-key</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Wijzig wachtwoord</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: routeNames.USER_DETAIL_EDIT_ROLE, params: { userId: userId } }">
          <v-list-item-icon>
            <v-icon>mdi-badge-account-horizontal</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Rol wijzigen</v-list-item-title>
        </v-list-item>
      </template>
    </BasicDetailHeaderComponent>
    <ConfirmationDialog :title="getToggleActiveDialogTitle" :description="getToggleActiveDialogDescription"
      v-model="toggleActiveDialog" :onConfirm="toggleActive" />
  </div>
</template>

<script>
import routeNames from "@/router/RouteNames";
import { isLatestElementOfList } from "@/shared/utils/CollectionUtils";
import format from "date-fns/format"
import ConfirmationDialog from "@/components/shared/ConfirmationDialog.vue";
import BasicDetailHeaderComponent from '../shared/BasicDetailHeaderComponent.vue';
import Editable from "@/components/shared/Editable";
import ModuleType from "@/shared/enums/moduleType";


export default {
  name: "UserDetailHeaderComponent",
  components: {
    ConfirmationDialog,
    BasicDetailHeaderComponent,
    Editable
  },
  props: {
    userId: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      routeNames: routeNames,
      isLatestElementOfList: isLatestElementOfList,
      toggleActiveDialog: false,
      promises: [this.$store.dispatch('usersModule/fetchBasicUser', this.userId), this.$store.dispatch('usersModule/fetchUserAvatar', this.userId)],
    }
  },
  methods: {
    format,
    
    onToggleActiveClick() {
      this.toggleActiveDialog = true
    },
    toggleActive() {
      this.$store.dispatch('usersModule/toggleUserActive', this.userId)
    },
  },
  computed: {
    getBasicUser() {
      return this.$store.state.usersModule.basicUser
    },
    getEnableTeamsOption() {
      return this.$store.getters["parameterModule/getEnableTeamsOption"]
    },
    getToggleActiveDialogTitle() {
      return `Gebruiker ${this.getBasicUser.firstName} ${this.getBasicUser.lastName} ${this.getBasicUser.active ? "deactiveren" : "activeren"}`
    },
    getToggleActiveDialogDescription() {
      return `Bent u zeker dat u gebruiker ${this.getBasicUser.firstName} ${this.getBasicUser.lastName} wilt ${this.getBasicUser.active ? "deactiveren" : "activeren"}?`
    },
    showRegisterHourButton(){
      return this.$store.getters["organizationModule/organizationHasModule"]([ModuleType.TIME_REGISTRATION])
    }
  },

}
</script>
